import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateComponent } from './template.component';
import { RouterModule } from '@angular/router';

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms'


import {CollapseModule} from 'ngx-bootstrap/collapse';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgxPrettyCheckboxModule} from 'ngx-pretty-checkbox';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {NgApexchartsModule} from 'ng-apexcharts'
import {CarouselModule} from 'ngx-owl-carousel-o';
import {TableModule} from 'primeng/table';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import { SharedModule } from '../../shared/module/share.module'; 





@NgModule({
  declarations: [
    TemplateComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
     FormsModule, ButtonsModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule,
    NgxPrettyCheckboxModule,
    NgApexchartsModule,
    CarouselModule,
    TableModule,
    CdkScrollableModule,
    SharedModule 
  ],
  exports: [
    TemplateComponent,
  ],
  providers: [],
  bootstrap: [TemplateComponent]
})
export class TemplateModule { }
