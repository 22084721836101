<div class="main-all layout-default">
    <div class="main-header layout-default">
        <div class="block-left-bar">
            <div class="left-bar-img">
                <a href="">
                    <img src="assets/image/menu_logo.jpg" alt="">
                </a>
            </div>
        </div>
    </div>

    <div class="main-body layout-default">
        <router-outlet></router-outlet>
    </div>
    <div class="main-footer layout-default">
        <div class="block-footer">
            <div class="copy col-12 col-lg-5">
                <p>{{'footer.ft_text1' | translate}}</p>
            </div>
            <div class="group-link col-12 col-lg-7">
                <ul>
                    <li>
                        <a routerLink="/policy" target="_blank">{{'footer.ft_text2' | translate}}</a>
                    </li>
                    <li>
                        <a routerLink="/disclaimer" target="_blank" href="">{{'footer.ft_text3' | translate}}</a>
                    </li>
                    <li>
                        <a class="unlink">
                            <i class="icon-ic-mail"></i> {{'footer.ft_text4' | translate}}
                        </a>
                    </li>
                    <li>
                        <a class="unlink">
                            <i class="icon-ic-call"></i> 02-633-6000 #4
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>