import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { UtilService } from '../../services/util.services';

@Component({
  selector: 'app-defaulttemplate',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  constructor(private router: Router,public util: UtilService){}

  ngOnInit(): void {
  }

  themeDefault
  typePassword: boolean;
  typePasswordConfirm: boolean;
  idenMaxLen = 13;
  titleFor = 'ตรวจสอบสถานะลาออก';
  step = 1
  workingStatus = 1;

}
